@import "../../styles/mixins/index.scss";

.mint {
  text-align: center;

  &__block {
    position: relative;
    top: -180px;
    @include xxl {
      position: initial;
    }
  }
  &__btn {
    background-color: var(--yellow);
    padding: 16px;
    width: 300px;
    border-radius: 30px;
    border: 2px solid var(--black);
    font-size: 28px;
    font-family: "Peignot", Arial;
  }
}

.input {
  font-family: Retrofunk Script Personal Use;
  text-align: center;
  font-size: 48px;
  max-width: 100px;
  text-shadow: 2px 2px var(--yellow);
  &__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0 20px;
  }
  &__btn {
    font-size: 36px;
  }
}
