@import "../mixins/index.scss";
@font-face {
  font-family: "Peignot";
  src: url("../fonts/Peignot/Peignot.woff2") format("woff2"),
    url("../fonts/Peignot/Peignot.woff") format("woff"),
    url("../fonts/Peignot/Peignot.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Retrofunk Script Personal Use";
  src: url("../fonts/Retrofunk/RetrofunkScriptPersonalUse.woff2")
      format("woff2"),
    url("../fonts/Retrofunk/RetrofunkScriptPersonalUse.woff") format("woff"),
    url("../fonts/Retrofunk/RetrofunkScriptPersonalUse.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Apple Chancery';
  src: url('../fonts/Apple-Chancery/Apple-Chancery.woff2') format('woff2'),
      url('../fonts/Apple-Chancery/Apple-Chancery.woff') format('woff'),
      url('../fonts/Apple-Chancery/Apple-Chancery.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}



:root {
  --black: #000000;
  --white: #ffffff;
  --begie: #edeae7;
  --green: #9ad319;
  --blue: #128DFF;
  --yellow: #f5b840;
  --yellow-sec: #D5A957;
  --yellow-third: #9E7A08;
  --yellow-fourth: #E4B862;
  --light-yellow: #fff3d0;
  --brown: #C8764F;
  --brown-third: #643721;
  --red: #c84f4f;
  --orange: #EA6C30;
  --violet: var(--yellow);
  --menu-bg-fixed: var(--yellow);
  --menu-bg-mobile: var(--yellow);
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Peignot", Arial;
  font-size: 22px;
  @include xl {
    font-size: 18px;
  }
}

h2, .h2 {
  --text-shadow: var(--green);
  font-family: Retrofunk Script Personal Use;
  font-size: 48px;
  font-weight: bold;
  font-weight: 400;
  line-height: 48px;
  margin-bottom: 30px;
  text-shadow: 2px 2px var(--text-shadow);
  @include xl {
    font-size: 36px;
  }
}

.font-retro {
  font-family: Retrofunk Script Personal Use;
}

h3 {
  font-size: 28px;
}

p {
  margin-bottom: 20px;
}

a {
  text-decoration: underline;
  color: var(--blue);
}

ul.list, ul.list-num {
  margin: 8px 0 8px 20px;
  li {
    list-style-type: disc;
  }
}

ul.list-num {
    li {
        list-style-type: decimal;
    }
}

.mb-0 {
    margin-bottom: 0;
}
.relative {
  position: relative;
  z-index: 10;
}

.container {
  margin-inline-start: auto;
  margin-inline-end: auto;
  max-width: 1320px;
  padding-inline-start: 24px;
  padding-inline-end: 24px;
  @include xl {
    max-width: initial;
    width: 100%;
  }
}

.p-md-0 {
  @include md {
    padding: 0;
  }
}

.text-center {
    text-align: center;
}
