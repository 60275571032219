.footer {
    text-align: center;
    background-image: url('../../assets/images/footer/bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-block-start: 80px;
    padding-block-end: 10px;
    color: var(--white);
    &__logo {
        margin-bottom: 54px;
    }
    h2 {
        --text-shadow: var(--brown);
        margin-bottom: 100px;
    }
}