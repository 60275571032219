@import "../../../styles/mixins/index.scss";

.team {
    position: relative;
    padding-block-start: 90px;
    background-color: var(--light-yellow);
    text-align: center;
    @include md {
      padding-block-start: 40px;
    }
    &__bg {
      background-image: url("../../../assets/images/team/bg.png");
      background-repeat: no-repeat;
      background-position: right bottom;
      @include lg {
        background-size: 200px;
      }
      @include sm {
        background-image: initial;
      }
    }
    &__ellipsis {
      position: absolute;
      left: -86%;
      top: -18%;
      z-index: 1;
      min-width: 800px;
      @include md {
        display: none;
      }
      @include xl {
        left: -15%;
        top: -5%;
        min-width: 400px;
      }
    }
    &::after {
      content: "";
      position: absolute;
      left: 40%;
      bottom: 28%;
      display: block;
      box-sizing: border-box;
      background-color: #ffcc6d;
      border: solid 60px #ffe69d;
      border-radius: 50%;
      width: 400px;
      height: 400px;
      @include lg {
        display: none;
      }
    }
    &__block {
      &-img {
        margin-bottom: 16px;
      }
      &-title {
        margin-bottom: 18px;
      }
      &-position {
        margin-bottom: 10px;
      }
    }
    &__wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 32px;
      margin: 60px 0;
      @include sm {
        margin-bottom: 0;
      }
    }
    &__item {
      position: relative;
      z-index: 10;
      width: calc(100% / 4 - 24px);
      @include xl {
        width: calc(100% / 3 - 24px);
      }
      @include md {
        width: calc(100% / 2 - 24px);
      }
      @include sm {
        width: 100%;
      }
    }
    &__title {
      position: relative;
      z-index: 100;
      color: var(--brown-third);
      --text-shadow: var(--yellow);
    }
  }
  

  
.thanks {
    background-image: url("../../../assets/images/thanks/flowers.png"),
      url("../../../assets/images/thanks/circle.png");
    background-position: bottom left, bottom center;
    background-size: auto, 1200px;
    background-repeat: no-repeat;
    padding: 20px 0;
    @include sm {
      display: none;
      background-image: initial;
      padding: 0;
    }
    &__title {
      color: var(--brown);
      --text-shadow: var(--brown-third);
      @include sm {
        background-color: var(--yellow);
        padding: 40px;
        margin-bottom: 0;
      }
    }
    &__wrap {
      display: inline-flex;
      height: 690px;
      padding: 50px 0;
      @include xxl {
        transform:scale(.8);
      }
      @include xl {
        transform: initial;
        width: 100%;
        height: auto;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
      }
      @include sm {
        padding: 0;
        gap: 0px;
      }
      & > div {
        h3 {
          margin-bottom: 20px;
        }
        background-color: var(--brown);
        border-radius: 50%;
        padding: 36px;
        width: 360px;
        height: 360px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        @include sm {
          width: 100%;
          height: auto;
          padding: 18px;
          border-radius: 0;
        }
        &:nth-child(1) {
          position: relative;
          top: 0%;
          background-color: var(--brown-third);
          color: var(--white);
        }
        &:nth-child(2) {
          position: relative;
          top: 26%;
          left: -1%;
          width: 332px;
          height: 332px;
          background-color: var(--brown);
          color: var(--white);
          @include sm {
            position: initial;
            width: 100%;
            height: auto;
          }
        }
        &:nth-child(3) {
          position: relative;
          left: -3%;
          width: 296px;
          height: 296px;
          background-color: var(--orange);
          color: var(--white);
          @include sm {
            position: initial;
            width: 100%;
            height: auto;
          }
        }
        &:nth-child(4) {
          position: relative;
          bottom: -50%;
          left: -13%;
          width: 284px;
          height: 284px;
          background-color: var(--yellow);
          color: var(--black);
          @include sm {
            position: initial;
            width: 100%;
            height: auto;
          }
        }
        &:nth-child(5) {
          position: relative;
          left: -17%;
          top: 16%;
          width: 262px;
          height: 262px;
          background-color: var(--yellow-sec);
          color: var(--black);
          @include sm {
            position: initial;
            width: 100%;
            height: auto;
          }
        }
        @include xl {
          position: initial !important;
        }
      }
    }
  }
  