@import "../../../styles/mixins/index.scss";

.join {
    background-image: url("../../../assets/images/roadmap/bg.png");
    background-color: var(--black);
    background-repeat: no-repeat;
    background-size: cover;
    padding-block-start: 64px;
    text-align: center;
    @include sm {
      background-image: initial;
    }
    &-social {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 36px;
      margin-bottom: 50px;
      a {
        display: inline-flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        padding: 16px;
        width: 300px;
        background-color: var(--orange);
        border-radius: 30px;
        color: var(--white);
        font-size: 28px;
        @include sm {
          font-size: 24px;
        }
        &:nth-child(1) {
          border: 2px solid var(--white);
        }
        &:nth-child(2) {
          border: 2px solid var(--yellow);
          background-color: var(--brown);
        }
        &:nth-child(3) {
          background-color: var(--yellow-sec);
          border: 2px solid var(--orange);
        }
      }
    }
    &__block {
      background-image: url("../../../assets/images/join/wrap.png");
      background-repeat: no-repeat;
      background-size: 1210px;
      background-position: bottom;
      padding: 80px;
      margin: auto;
      @include md {
        padding: 40px 24px;
        background-image: initial;
        background-color: var(--yellow);
      }
      &-text {
        padding-top: 240px;
        max-width: 740px;
        margin: auto;
        @include xl {
          padding-top: 40vw;
        }
        @include md {
          padding-top: 0;
        }
      }
      &-title {
        text-shadow: initial;
      }
    }
    &__title {
      color: var(--white);
      --text-shadow: var(--orange);
      margin-bottom: 70px;
      @include sm {
        margin-bottom: 50px;
      }
    }
  }
  