@import "../../../styles/mixins/index.scss";

.roadmap {
  position: relative;
  padding: 45px 0;
  background-color: var(--light-yellow);
  // background-image: url("../../../assets/images/roadmap/bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
  overflow: hidden;
  @include md {
    padding: 0;
  }
  &__mobile {
    @include md {
      background-color: var(--light-yellow);
      text-align: center;
      padding: 40px;
    }
  }
  &__first {
    background-image: url(http://localhost:3000/static/media/ellips.7ba2a166d57df40a29b1.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
  }
  &__link {
    display: none;
    @include md {
      display: block;
    }
  }
  &__map-img {
    position: relative;
    z-index: 10;
    @include md {
      display: none;
    }
  }
  &__first-info {
    display: flex;
    text-align: center;
    gap: 80px;
    position: relative;
    z-index: 1;
    @include xl {
      gap: 0;
    }
    @include md {
      flex-direction: column;
    }
    & > div {
      position: relative;
      top: -240px;
      padding: 260px 80px 60px 80px;
      border-radius: 50%;
      @include xl {
        padding: 222px 60px 60px 60px;
      }
      @include md {
        position: initial;
        padding: 40px;
        border-radius: 0;
      }
      p {
        @include md {
          margin-bottom: 0;
        }
      }
      &:first-child {
        background-color: var(--yellow-sec);
      }
      &:last-child {
        background-color: var(--brown);
      }
    }
  }
  h2 {
    --text-shadow: transparent;
  }
  &__bg-bottom {
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
  }
}

.future {
  text-align: center;
  top: -180px;
  position: relative;
  z-index: 15;
  @include md {
    top: 0;
  }
  &__yellow {
  }
  &__owl {
    position: absolute;
    top: -160px;
    right: 50%;
    @include md {
      display: none;
    }
  }
  &__eagle {
    position: absolute;
    z-index: 11;
    left: -15%;
    bottom: 10%;
    @include md {
      display: none;
    }
  }
  &__block {
    width: 96%;
    margin: auto;
    background-color: var(--yellow);
    border-radius: 50%;
    position: relative;
    z-index: 10;
    @include md {
      padding: 0;
      background-color: initial;
      border-radius: 0;
      width: 100%;
    }
    &-first-part {
      position: relative;
      padding: 0 10px;
      margin-bottom: 40px;
      @include md {
        margin-bottom: 0;
      }
      &__first-title {
        padding-top: 20px;
        @include xl {
          margin-bottom: 0;
        }
        @include md {
          padding-top: 0px;
        }
      }
      &__second-title {
        font-family: "Apple Chancery", Arial;
        color: var(--yellow);
        margin-bottom: 4px;
      }
      &__third-title {
        color: var(--orange);
        --text-shadow: var(--brown-third) !important;
        margin-bottom: 0;
      }
      &_ellipse {
        position: absolute;
        left: 50%;
        top: 0;
        background-repeat: no-repeat;
        z-index: -1;
        height: 110%;
        width: 77%;
        transform: translateX(-50%);
        @include xl {
          top: 0;
          width: 88%;
        }
      }
      @include md {
        background-color: var(--yellow-third);
        padding: 40px 0;
      }
    }
    &-second-part {
      padding: 0 18% 20%;
      @include md {
        padding: 40px 24px;
        background-color: var(--yellow);
      }
      h3 {
        font-family: Retrofunk Script Personal Use;
      }
    }
  }
  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 45%;
    z-index: 1;

    top: 2%;
    background-color: var(--orange);
    &_black {
      content: "";
      position: absolute;
      z-index: 0;
      top: -74px;
      left: 0;
      width: 120%;
      height: 120%;
      transform: scale(1.15) translateX(-7%);
      background-color: var(--black);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;
      border-radius: 80%;
      @include md {
        display: none;
      }
    }
    @include md {
      display: none;
    }
  }
}
