@import "../../../styles/mixins/index.scss";

.education {
  background-color: var(--brown);
  padding: 64px 0;
  &__title {
    text-align: center;
    --text-shadow: var(--yellow);
    color: var(--black);
    text-decoration: none;
    margin-bottom: 60px;
  }
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px;
    gap: 20px;
  }
  &__card {
    background-color: var(--white);
    width: calc(100% / 3 - 20px);
    border-radius: 26px;
    @include sm {
        width: 100%;
    }
    &-info {
      padding: 20px;
    }
    &-title {
      text-decoration: none;
      color: var(--black);
      margin-bottom: 20px;
    }
    &-desc {
      color: var(--black);
    }
    &__img {
      width: 100%;
      border-top-left-radius: 26px;
      border-top-right-radius: 26px;
    }
  }
  &__link {
    display: block;
    margin: auto;
    background-color: var(--orange);
    border: 2px solid var(--brown-third);
    padding: 15px;
    width: 298px;
    font-size: 28px;
    color: var(--white);
    text-decoration: none;
    border-radius: 30px;
    text-align: center;
  }
  a {
    text-decoration: none;
  }
}
