@import "../../../styles/mixins/index.scss";

.mint {
    background-color: var(--yellow-fourth);
   @include md {
    padding-block-start: 40px;
    text-align: center;
   } 
    &__title {
        --text-shadow: var(--brown);
        margin-bottom: 60px;
    }
    &__btn {
        background-color: var(--orange);
        padding:15px 50px ;
        border: solid var(--brown-third) 2px;
        border-radius: 30px;
        color: var(--white);
        font-family: 'Peignot';
        font-size: 28px;
        font-weight: 400;
    }
    &__wrap {
        display: flex;
        align-items: center;
        gap: 30px;
        padding-block-start: 70px;
        @include md {
            padding-block-start: 0px;
            flex-direction: column;
        }
    }
}