@import "../../../styles/mixins/index.scss";

.intro {
  min-height: 100vh;
  background-image: url("../../../assets/images/intro/zebra.png"),
    url("../../../assets/images/intro/wolf.png"),
    url("../../../assets/images/intro/characters.png"),
    url("../../../assets/images/intro/bg.png");
  background-repeat: no-repeat;
  background-size: auto, auto, auto, cover;
  background-position: left bottom, right bottom, bottom center, center center;
  @include xxl {
    background-image: url("../../../assets/images/intro/characters-full.png"),
      url("../../../assets/images/intro/bg.png");
    background-size: contain, cover;
  }
  @include xxl {
    min-height: 650px;
  }
  @include sm {
    min-height: initial;
    background-image: url("../../../assets/images/intro/bg-mobile.png");
    background-size: cover;
  }
  &__characters {
    display: none;
    width: 100%;
    @include sm {
      display: block;
    }
  }
  &__text {
    margin: 0 auto;
    padding-block-start: 160px;
    text-align: center;
    @include xxxl {
      max-width: 800px;
    }
    @include lg {
      padding-block-start: 60px;
    }
  }
}
