@import "../../../styles/mixins/index.scss";

.poppy {
  position: relative;
  background-color: var(--brown);
  padding: 112px 0;
  background-image: url("../../../assets/images/poppy/poppies.png"),
    url("../../../assets/images/poppy/eye.png");
  background-repeat: no-repeat;
  background-size: contain, contain;
  background-position: 0px -140px, bottom;
  overflow: hidden;
  img {
    @include lg {
      margin-bottom: 20px;
    }
  }
  @include lg {
    padding: 40px 0;
    background-position: top, bottom;
  }
  &__title {
    text-align: center;
    --text-shadow: var(--yellow);
  }
  &__wrap {
    &-intro {
      position: relative;
      z-index: 10;
      display: flex;
      justify-content: space-between;
      gap: 40px;
      margin-top: 100px;
      padding-bottom: 14vw;
      @include lg {
        margin-top: 0;
      }
      @include xl {
        flex-direction: column;
        text-align: center;
        padding-bottom: 40px;
      }
      &__img {
        margin-top: 10px;
        position: relative;
        height: max-content;
        @include xl {
          max-width: 500px;
          margin: auto;
        }
        &::after {
          content: "";
          width: 120px;
          height: 120px;
          position: absolute;
          right: -60px;
          bottom: 0;
          background-color: var(--black);
          border: solid 70px var(--orange);
          border-radius: 50%;
          box-sizing: content-box;
        }
        img {
          position: relative;
          z-index: 1;
          width: 100%;
          object-fit: fill;
        }
      }
      &__block {
        max-width: 520px;
        @include xl {
          max-width: initial;
        }
      }
      &__title {
        --text-shadow: var(--white) !important;
      }
      &__text {
        position: relative;
      }
    }
  }
  &__lines {
    position: absolute;
    z-index: 5;
    bottom: -5%;
    width: 100%;
    @include xl {
      display: none;
    }
    @include lg {
      display: block;
      position: initial;
      margin-bottom: 40px;
    }
    img {
      width: 100%;
    }
  }
  &__june {
    margin-top: 100px;
    &-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      @include lg {
        flex-direction: column;
      }
    }
    &-img {
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 10;
      max-width: 500px;
      transform: translateY(-50%);
      @include xl {
        max-width: 372px;
      }
      @include lg {
        position: initial;
        transform: translateY(0%);
        margin: auto;
      }
    }
    &-block {
      padding: 60px 160px;
      position: relative;
      z-index: 4;
      background-color: var(--yellow-sec);
      border: solid var(--yellow) 40px;
      max-width: 74%;
      border-radius: 50%;
      box-sizing: border-box;
      @include xl {
        padding: 60px 118px;
      }
      @include lg {
        max-width: initial;
        border: none;
        border-radius: 0;
        background: none;
        max-width: initial;
        padding: 0;
        text-align: center;
      }
      h2 {
        --text-shadow: var(--brown);
      }
    }
  }
  &__stewart {
    margin-top: 100px;
    &-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include lg {
        flex-direction: column-reverse;
      }
    }
    &-img {
      position: relative;
      z-index: 3;
      top: -50px;
      left: 5%;
      @include xl {
        max-width: 372px;
      }
      @include lg {
        position: initial;
        transform: translateY(0%);
        margin: auto;
      }
    }
    &-block {
      padding: 170px 60px;
      position: relative;
      top: -50px;
      right: 0;
      z-index: 1;
      background-color: var(--yellow);
      border: solid var(--orange) 40px;
      border-radius: 50%;
      box-sizing: border-box;
      width: 72%;
      @include xl {
        padding: 60px 118px;
      }
      @include lg {
        width: 100%;
        margin-top: 40px;
        position: initial;
        max-width: initial;
        border: none;
        border-radius: 0;
        background: none;
        max-width: initial;
        padding: 0;
        text-align: center;
      }
      h2 {
        --text-shadow: var(--brown);
      }
    }
  }
  &__henry {
    margin-top: 100px;
    &-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include lg {
        flex-direction: column;
      }
    }
    &-img {
      position: relative;
      z-index: 3;
      top: -50px;
      right: 0;
      @include xl {
        max-width: 372px;
      }
      @include lg {
        position: initial;
        transform: translateY(0%);
        margin: auto;
      }
    }
    &-content {
      border-radius: 50%;
      padding: 170px 160px;
      width: 100%;
      background-color: var(--brown);
      z-index: 1000;
      position: relative;
      @include xl {
        padding: 70px;
      }
      @include lg {
        width: 100%;
        margin-top: 40px;
        position: initial;
        max-width: initial;
        border: none;
        border-radius: 0;
        background: none;
        max-width: initial;
        padding: 0;
        text-align: center;
      }
    }
    &-disc {
      width: 200px;
      height: 200px;
      background-color: var(--yellow);
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: -20px;
      @include lg {
        display: none;
      }
    }
    &-block {
      position: relative;
      top: -50px;
      left: -40px;
      z-index: 1;
      background-color: transparent;
      border: solid var(--brown-third) 60px;
      border-radius: 50%;
      box-sizing: border-box;
      width: 90%;
      color: var(--white);
      @include lg {
        width: 100%;
        margin-top: 40px;
        position: initial;
        max-width: initial;
        border: none;
        border-radius: 0;
        background: none;
        max-width: initial;
        padding: 0;
        text-align: center;
      }
      @include lg {
        margin-top: 0;
      }
      &:before {
        border: solid var(--brown-third) 60px;
        border-radius: 50%;
      }
      h2 {
        --text-shadow: var(--brown-third);
      }
    }
  }
  &__arlo {
    margin-top: 100px;
    &-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      @include lg {
        flex-direction: column-reverse;
      }
    }
    &-img {
      position: absolute;
      z-index: 3;
      top: 40%;
      transform: translate(0, -50%);
      left: 4%;
      max-width: 500px;
      @include lg {
        margin: auto;
        position: initial;
        transform: translateY(0%);
      }
      @include xl {
        max-width: 372px;
      }
    }
    &-disc {
      width: 120px;
      height: 120px;
      background-color: var(--brown);
      border: solid var(--light-yellow) 20px;
      border-radius: 50%;
      position: absolute;
      top: 110px;
      right: 25%;
      @include lg {
        display: none;
      }
    }
    &-block {
      position: relative;
      top: -190px;
      right: -215px;
      z-index: 1;
      background-color: transparent;
      border: solid var(--yellow-sec) 60px;
      border-radius: 50%;
      box-sizing: border-box;
      width: 80%;
      color: var(--white);
      padding: 170px 160px 170px 300px;
      background-color: var(--brown-third);
      z-index: 1;
      position: relative;
      @include xl {
        padding: 170px 85px 170px 154px;
      }
      @include lg {
        padding: 0;
        width: 100%;
        margin-top: 40px;
        position: initial;
        max-width: initial;
        border: none;
        border-radius: 0;
        background: none;
        max-width: initial;
        padding: 0;
        text-align: center;
      }
      h2 {
        --text-shadow: var(--brown);
      }
    }
  }
  &__zebra {
    &-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include lg {
        flex-direction: column;
      }
    }
    &-img {
      position: relative;
      top: -220px;
      z-index: 100;
      @include xl {
        top: 0;
        max-width: 372px;
      }
      @include lg {
        margin: auto;
      }
    }
    &-block {
      padding: 120px 160px;
      position: relative;
      left: -120px;
      z-index: 4;
      background-color: var(--yellow);
      border: solid var(--white) 32px;
      max-width: 61%;
      border-radius: 50%;
      box-sizing: border-box;
      @include xl {
        padding: 60px 100px;
        max-width: 62%;
      }
      @include lg {
        padding: 0;
        width: 100%;
        margin-top: 40px;
        position: initial;
        max-width: initial;
        border: none;
        border-radius: 0;
        background: none;
        max-width: initial;
        padding: 0;
        text-align: center;
      }
      h2 {
        --text-shadow: var(--brown);
      }
    }
  }
}
