@import "../../styles/mixins/index.scss";

.header {
  padding: 5px 0;
  transition: background-color 0.3s;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 10000000;
  @include sm {
    padding: 8px 0;
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo {
    font-family: Retrofunk Script Personal Use;
    font-size: 48px;
    text-decoration: none;
    color: var(--white);
    text-shadow: 2px 2px var(--yellow);
    transition: font-size 0.3s;
    @include sm {
      font-size: 32px;
    }
    &_mint {
      color: var(--black);
    }
  }
  &_fixed {
    background-color: var(--menu-bg-fixed);
    .menu a {
      color: var(--white);
      font-size: 20px;
      text-decoration: none;
    }
    .header__logo {
     font-size: 28px;
    }
  }
}

.menu {
  display: flex;
  gap: 45px;
  &__link {
    color: var(--white);
    transition: 0.3s;
    text-decoration: none;
    &:hover {
      color: var(--green);
    }
  }
  @include xl {
    gap: 20px;
  }
  @include lg {
    flex-direction: column;
    position: absolute;
    gap: 16px;
    top: 44px;
    display: none;
    z-index: 1000;
    &__open {
      display: flex;
      width: 100%;
      left: 0;
      padding-inline-start: 20px;
      background-color: var(--menu-bg-mobile);
      height: 100vh;
      padding-block-start: 20px;
      padding-block-end: 20px;
      overflow: auto;
    }
  }
}

.burger {
  &__btn {
    cursor: pointer;
    display: none;
    background: none;
    @include lg {
      display: block;
      height: 24px;
      width: 30px;
    }
  }
  &__icon {
    display: block;
    background-color: var(--white);
    height: 4px;
    width: 30px;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 2px;
    position: absolute;

    &:before {
      @extend .burger__icon;
      content: "";
      margin-top: -8px;
    }
    &::after {
      @extend .burger__icon;
      content: "";
      margin-top: 8px;
    }
    &_open {
      background: transparent;
      &::before {
        margin-top: 0px;
        transform: rotate(405deg);
      }
      &::after {
        margin-top: 0px;
        transform: rotate(-405deg);
      }
    }
  }
}
