@import "../../../styles/mixins/index.scss";

.about {
  min-height: 100vh;
  background-color: var(--light-yellow);
  background-image: url("../../../assets/images/about/girl.png");
  background-repeat: no-repeat;
  background-position: right, center;
  background-size: contain;
  padding: 60px 0;
  @include sm {
    padding: 40px 0;
    min-height: 400px;
  }
  @include lg {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-image: none;
    padding-bottom: 0;
  }
  &__title {
    --text-shadow: var(--green);
  }
  &__block {
    max-width: 50%;
    @include lg {
      max-width: initial;
    }
  }
  &__girl-img {
    width: 100%;
  }
}
