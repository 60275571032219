@import "../../styles/mixins/index.scss";

.mint {
  background-image: url("../../assets/images/poppy/poppies.png");
  background-repeat: no-repeat;
  background-position: 0 -360px;
  background-size: cover;
  background-color: var(--brown);
  min-height: 100vh;
  text-align: center;

  h2 {
    --text-shadow: var(--yellow);
    margin-bottom: 16px;
  }
  &__wrap {
    padding-block-start: 150px;
    display: flex;
    align-items: flex-end;
    gap: 20px;
    @include sm {
      padding-block-start: 10px;
    }
    @include lg {
      flex-direction: column;
      gap: 60px;
    }
    @include xxl {
      align-items: center;
      justify-content: space-between;
    }
    img {
      @include xxl {
        max-width: 500px;
      }
      @include md {
        max-width: 310px;
      }
    }
  }
  &__video {
    border-radius: 20px;
    max-width: 300px;
    width: 100%;
    object-fit: contain;
  }
}